import { Accordion, Grid, Typography } from "@mui/material";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import HeroSection from "../HeroSection";
import Pillar from "../Pillar";
import "./Pillars.css";

const titles = [
  "Pillar 1:  ERADICATION OF PATHOGEN RESERVOIRS",
  "Pillar 2:  BETTER TECHNOLOGY TO DESTROY BIOFILMS",
  "Pillar 3:  REENGINEERED CATHETERS",
  "Pillar 4:  OCCUPIED ENVIRONMENT DISINFECTION",
  "Pillar 5:  FASTER AND BETTER HAND DISINFECTION",
  "Pillar 6:  ADOPTING “THE GOAL IS ZERO” MINDSET",
  "Pillar 7:  COLLABORATION and REDUCING CLIENT RISK",
];

function Pillars() {
  const [pillar1Top, setPillar1Top] = useState("");
  const [pillar1Bottom, setPillar1Bottom] = useState("");
  const [pillar2Top, setPillar2Top] = useState("");
  const [pillar2Bottom, setPillar2Bottom] = useState("");
  const [pillar3Top, setPillar3Top] = useState("");
  const [pillar3Bottom, setPillar3Bottom] = useState("");
  const [pillar4Top, setPillar4Top] = useState("");
  const [pillar4Bottom, setPillar4Bottom] = useState("");
  const [pillar5Top, setPillar5Top] = useState("");
  const [pillar5Bottom, setPillar5Bottom] = useState("");
  const [pillar6Top, setPillar6Top] = useState("");
  const [pillar6Bottom, setPillar6Bottom] = useState("");
  const [pillar7Top, setPillar7Top] = useState("");
  const [pillar7Bottom, setPillar7Bottom] = useState("");

  useEffect(() => {
    fetch("/html/topPillar1.html")
      .then((res) => res.text())
      .then((text) => setPillar1Top(text));
    fetch("/html/bottomPillar1.html")
      .then((res) => res.text())
      .then((text) => setPillar1Bottom(text));
    fetch("/html/topPillar2.html")
      .then((res) => res.text())
      .then((text) => setPillar2Top(text));
    fetch("/html/bottomPillar2.html")
      .then((res) => res.text())
      .then((text) => setPillar2Bottom(text));
    fetch("/html/topPillar3.html")
      .then((res) => res.text())
      .then((text) => setPillar3Top(text));
    fetch("/html/bottomPillar3.html")
      .then((res) => res.text())
      .then((text) => setPillar3Bottom(text));
    fetch("/html/topPillar4.html")
      .then((res) => res.text())
      .then((text) => setPillar4Top(text));
    fetch("/html/bottomPillar4.html")
      .then((res) => res.text())
      .then((text) => setPillar4Bottom(text));
    fetch("/html/topPillar5.html")
      .then((res) => res.text())
      .then((text) => setPillar5Top(text));
    fetch("/html/bottomPillar5.html")
      .then((res) => res.text())
      .then((text) => setPillar5Bottom(text));
    fetch("/html/topPillar6.html")
      .then((res) => res.text())
      .then((text) => setPillar6Top(text));
    fetch("/html/bottomPillar6.html")
      .then((res) => res.text())
      .then((text) => setPillar6Bottom(text));
    fetch("/html/topPillar7.html")
      .then((res) => res.text())
      .then((text) => setPillar7Top(text));
    fetch("/html/bottomPillar7.html")
      .then((res) => res.text())
      .then((text) => setPillar7Bottom(text));
  }, []);

  return (
    <div>
      <HeroSection
        cssClass="hero-container-pillars"
        title="Seven Path-X"
        title2=" Pillars for Sustained HAI Reductions"
        homepage={true}
      />
      <div className="goalsList">
        <Grid container spacing={0} alignItems="center" justify="center">
          <Box
            width={isMobile ? "100%" : "80%"}
            alignItems="center"
            margin="auto"
            justifyContent="center"
            padding={isMobile ? "0px" : "20px"}
            backgroundColor="#EEE"
          >
            <Accordion TransitionProps={{ unmountOnExit: true }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="subtitle2" fontSize={"1.5rem"}>
                  We believe:
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <ul>
                  <li>
                    <Typography variant="subtitle1" fontSize={"1.2rem"} mt={isMobile ? -2 : -5}>
                      Bathrooms can become some of the most germ-free
                      environments in a hospital
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="subtitle1" fontSize={"1.2rem"}>
                      Biofilms can be beaten
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="subtitle1" fontSize={"1.2rem"}>
                      HAI’s in healthcare and nursing home facilities can be
                      drastically reduced <br />
                      (our short term goal: &gt;50% reductions)
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="subtitle1" fontSize={"1.2rem"}>
                      Drastic reductions in HAI’s may be achieved with a
                      multifaceted, Seven Pillar approach, seven separate areas
                      of focused activity and attention that are all required to
                      achieve and sustain drastic HAI reductions
                    </Typography>
                  </li>
                </ul>
              </AccordionDetails>
            </Accordion>

            <Pillar title={titles[0]} top={pillar1Top} bottom={pillar1Bottom} />
            <Pillar title={titles[1]} top={pillar2Top} bottom={pillar2Bottom} />
            <Pillar title={titles[2]} top={pillar3Top} bottom={pillar3Bottom} />
            <Pillar title={titles[3]} top={pillar4Top} bottom={pillar4Bottom} />
            <Pillar title={titles[4]} top={pillar5Top} bottom={pillar5Bottom} />
            <Pillar title={titles[5]} top={pillar6Top} bottom={pillar6Bottom} />
            <Pillar title={titles[6]} top={pillar7Top} bottom={pillar7Bottom} />
          </Box>
        </Grid>
      </div>
    </div>
  );
}

export default Pillars;
