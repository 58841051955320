import { Paper } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { isMobile } from "react-device-detect";
import HeroSection from "../HeroSection";
import "./About.css";

function About() {
  return (
    <div>
      <HeroSection cssClass="hero-container-about" title="About Us" />
      <Box
        width={isMobile ? "100%" : "80%"}
        alignItems="center"
        margin="auto"
        justifyContent="center"
        padding={isMobile ? "0px" : "20px"}
        backgroundColor="#EEE"
      >
        <Paper elevation={24}>
          <div className="person">
            <img src="/images/ralph.jpg" alt="Ralph" />
            <p>
              <b>PathX™</b> and Lloyd Technologies, Inc. are founded by Ralph
              Lloyd, innovator, entrepreneur, and chemical engineer. In his 32
              years’ service at DuPont, Ralph grappled with some of the most
              challenging problems of technology and science along side some of
              the world’s best scientists and engineers. This experience taught
              Ralph the skills of deeply understanding the fundamental science
              behind complex problems and pursuing bold, ground-breaking,
              never-before-conceived solutions to those problems.
              <br />
              <br />
              Ralph and his network of DuPont colleagues have over a hundred
              years combined experience in chemistry and engineering and are
              excited to bring that experience to the problem of HAI’s and the
              emergence of antibiotic resistant organisms, some of the greatest
              challenges facing healthcare today.
            </p>
          </div>
          <div></div>
        </Paper>
      </Box>
    </div>
  );
}

export default About;
