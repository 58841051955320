import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import parse from "html-react-parser";
import "./Pillar.css";
import ContactButton from "./ContactButton";
import { Box } from "@mui/system";

function Pillar({ title, top, bottom }) {
  return (
    <Accordion TransitionProps={{ unmountOnExit: true }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="mainPanel-content"
        id="mainPanel-header"
      >
        <Typography variant="h6">Path-X<sup>®</sup> {title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div className="pillarText">
          {parse(top)}
        </div>
        <Accordion
          TransitionProps={{ unmountOnExit: true }}
          elevation={1}
          variant="outlined"
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="subPanel-content"
            id="subPanel-header"
          >
            <Typography variant="h6">What are we doing about it?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="pillarText">
              {parse(bottom)}
              <Box textAlign={"center"}>
                <ContactButton />
              </Box>
            </div>
          </AccordionDetails>
        </Accordion>
      </AccordionDetails>
    </Accordion>
  );
}

export default Pillar;
