import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Snackbar,
  IconButton,
  Button,
} from "@mui/material";
import React, { useState } from "react";
import { isMobile } from "react-device-detect";
import "./Contact.css";
import HeroSection from "../HeroSection";
import CloseIcon from "@mui/icons-material/Close";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Contact() {
  const [formState, setFormState] = useState({
    reason: "",
    name: "",
    email: "",
    message: "",
  });

  const [snackbarState, setSnackbarState] = useState({
    open: false,
    message: "",
    severity: "",
  });

  const handleClose = (e, r) => {
    if (r === "clickaway") {
      return;
    }

    setSnackbarState({ ...snackbarState, open: false });
  };

  const handleChange = (e) => {
    setFormState({ ...formState, reason: e.target.value });
  };

  const handleContactFormSubmit = async (e) => {
    e.preventDefault();
    const { reason, name, email, message } = formState;
    const endpoint =
      "https://e6bjqa1nr5.execute-api.us-east-1.amazonaws.com/default/pathxeabd380f-staging";
    const body = JSON.stringify({
      senderReason: reason,
      senderName: name,
      senderEmail: email,
      message: message,
    });
    const requestOptions = {
      method: "POST",
      body,
    };
    if (reason && name && email && message) {
        fetch(endpoint, requestOptions)
          .then((response) => {
            if (!response.ok) throw new Error("Error in fetch");
            setSnackbarState({
              open: true,
              message: "Error in fetch",
              severity: "error",
            });
            return response.json();
          })
          .then((response) => {
            setSnackbarState({
              open: true,
              message: "Successfully submitted!",
              severity: "success",
            });
            setFormState({ reason: "", name: "", email: "", message: "" });
          })
        .catch ((e) => {
        setSnackbarState({
          open: true,
          message: e.message,
          severity: "error",
        });
      })
    } else {
      setSnackbarState({
        open: true,
        message: "Please verify all fields are filled out.",
        severity: "error",
      });
    }
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div>
      <HeroSection cssClass="hero-container-contact" title="Contact Us" />
      <Grid container spacing={0} alignItems="center" justify="center">
        <Box
          width={isMobile ? "100%" : "80%"}
          alignItems="center"
          margin="auto"
          justifyContent="center"
          padding={isMobile ? "0px" : "20px"}
          backgroundColor="#EEE"
        >
          <Paper elevation={24}>
            <div className="contactForm">
              <form onSubmit={handleContactFormSubmit}>
                <FormControl fullWidth>
                  <InputLabel id="placeholder">
                    Reason for contacting us
                  </InputLabel>
                  <Select
                    labelId="placeholder"
                    value={formState.reason}
                    label="Reason for contacting us"
                    placeholder="Reason for contacting us"
                    onChange={handleChange}
                  >
                    <MenuItem disabled value="">
                      Reason for contacting us
                    </MenuItem>
                    <MenuItem
                      value={
                        "I am an infection control technology developer, and I am interested in partnering with PathX to develop or license disinfection technology."
                      }
                      style={{ whiteSpace: "normal" }}
                    >
                      I am an infection control technology developer, and I am
                      interested in partnering with PathX™ to develop or license
                      disinfection technology.
                    </MenuItem>
                    <MenuItem
                      value={
                        "I am a healthcare provider, and I am interested in learning more about how PathX can help my facility reduce HAI’s."
                      }
                      style={{ whiteSpace: "normal" }}
                    >
                      I am a healthcare provider, and I am interested in
                      learning more about how PathX™ can help my facility reduce
                      HAI’s.
                    </MenuItem>
                    <MenuItem
                      value={
                        "I am a research institution, and I am interested in partnering with PathX to develop and test cutting-edge disinfection technologies."
                      }
                      style={{ whiteSpace: "normal" }}
                    >
                      I am a research institution, and I am interested in
                      partnering with PathX™ to develop and test cutting-edge
                      disinfection technologies.
                    </MenuItem>
                    <MenuItem
                      value={
                        "I am an investor interested in disinfection technology investments."
                      }
                      style={{ whiteSpace: "normal" }}
                    >
                      I am an investor interested in disinfection technology
                      investments.
                    </MenuItem>
                    <MenuItem
                      value={
                        "I like the PathXTM Pillars to sustained HAI reductions and want to join The Goal is Zero movement!"
                      }
                      style={{ whiteSpace: "normal" }}
                    >
                      I like the PathX™ Pillars to sustained HAI reductions and
                      want to join the “The Goal is Zero” movement!
                    </MenuItem>
                  </Select>
                  <div>
                    <TextField
                      required
                      label="Name"
                      value={formState.name}
                      placeholder="John Doe"
                      fullWidth
                      margin="dense"
                      onChange={(e) =>
                        setFormState({ ...formState, name: e.target.value })
                      }
                    />
                  </div>
                  <div>
                    <TextField
                      required
                      label="email"
                      value={formState.email}
                      placeholder="john.doe@email.com"
                      fullWidth
                      margin="dense"
                      onChange={(e) =>
                        setFormState({ ...formState, email: e.target.value })
                      }
                    />
                  </div>

                  <div>
                    <TextField
                      required
                      label="Message"
                      value={formState.message}
                      placeholder="Enter your message"
                      multiline
                      rows={10}
                      fullWidth
                      margin="dense"
                      onChange={(e) =>
                        setFormState({ ...formState, message: e.target.value })
                      }
                    />
                  </div>
                  <div align="center">
                    <Button
                      type="submit"
                      sx={{
                        width: isMobile ? "75%" : "25%",
                        marginTop: 2,
                        fontSize: "1.25rem",
                      }}
                      variant="contained"
                      onClick={handleContactFormSubmit}
                    >
                      Submit
                    </Button>
                  </div>
                  <div>
                    <Snackbar
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      open={snackbarState.open}
                      autoHideDuration={4000}
                      onClose={handleClose}
                      message={snackbarState.message}
                      action={action}
                    >
                      <Alert
                        onClose={handleClose}
                        severity={snackbarState.severity}
                        sx={{ width: "100%" }}
                      >
                        {snackbarState.message}
                      </Alert>
                    </Snackbar>
                  </div>
                </FormControl>
              </form>
            </div>
          </Paper>
        </Box>
      </Grid>
    </div>
  );
}

export default Contact;
