import { Button } from "@mui/material";
import React from "react";
import { isMobile } from "react-device-detect";

function ContactButton() {
  return (
    <div>
        <Button
          variant="contained"
          sx={{ 
            minWidth: isMobile ? "80%" : "50%",
            fontSize : "20px",
            textDecoration: "none",
            }}
          size="large"
          href="/contact"
          target={"_top"}
        >
          Contact Us
        </Button>
    </div>
  );
}

export default ContactButton;
