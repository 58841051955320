import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useState, useEffect } from "react";
import { isMobile } from "react-device-detect";
import HeroSection from "../HeroSection";
import parse from "html-react-parser";
import "./BathX.css";

function createData(
  className = "",
  location = "",
  first = "",
  second = "",
  third = ""
) {
  return { className, location, first, second, third };
}

const rows = [
  createData("title", "Inside Toilet Bowl"),
  createData("", "Under Rim, right¹", "26", "3"),
  createData("", "Bowl Surface (above water), right", "173", "7"),
  createData("", "Under Rim, front¹", "75", "15", "2"),
  createData("", "Bowl Surface, front", "91", "21", "5"),
  createData("", "Under Rim, left¹", "258", "1"),
  createData("", "Bowl Surface, left", "81", "5"),
  createData("", "Under Rim, rear¹", "3083", "2"),
  createData("", "Bowl Surface, rear", "17", "3"),

  createData("title", "Shower/Tub"),
  createData("", "Handle", "225", "5381²", "16"),
  createData("", "Tub Floor, left of drain", "4511", "38"),
  createData("", "Top of Drain Plug and Drain Collar", "6941", "38"),
  createData("", "Tub Floor, right of drain", "6204", "8", "22"),
  createData("", "Inside Drain¹", "6559", "6"),
  createData("", "Tub Floor Center", "1789", "557³"),
  createData("", "Shower Sidewall, Tile and Grout", "192", "3"),
  createData("", "Shower Sidwall, Tile and Grout", "156", "7"),

  createData("title", "Sink"),
  createData("", "Top of Drain Plug and Drain Collar", "4351", "46"),
  createData("", "Inside Drain Collar Wall¹", "1951", "10"),
  createData("", "Drain Plug, down Inside drain¹ (avg)", "6863", "95"),
  createData("", "Sink Basin, right of drain", "770", "13"),
  createData("", "Sink Counter, left of faucet", "1876", "6"),
];

function BathX() {
  const [content, setContent] = useState("");

  useEffect(() => {
    fetch("/html/bathx.html")
      .then((res) => res.text())
      .then((text) => setContent(text));
  }, []);

  return (
    <div>
      <HeroSection
        cssClass="hero-container-bathx"
        title="Bath-X"
        homepage={true}
      />
      <Grid container spacing={0} alignItems="center" justify="center">
        <Box
          width={isMobile ? "100%" : "80%"}
          alignItems="center"
          margin="auto"
          justifyContent="center"
          padding={isMobile ? "0px" : "20px"}
          backgroundColor="#EEE"
        >
          <Paper elevation={24}>
            <div className="bathx">
              {parse(content)}
              <Box
                width={isMobile ? "100%" : "80%"}
                alignItems="center"
                margin="auto"
                justifyContent="center"
                backgroundColor="#FFF"
              >
                <Typography
                  variant="h4"
                  align={isMobile ? "center" : "left"}
                  sx={{ maxWidth: 1000, margin: "auto", marginBottom: 1 }}
                >
                  Bath-X<sup>®</sup> ATP Results
                </Typography>
                {isMobile ? (
                  <TableContainer
                    component={Paper}
                    sx={{ maxWidth: 650, maxHeight: 440 }}
                  >
                    <Table
                      stickyHeader
                      aria-label="Bath-X<sup>®</sup> ATP Results"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell
                            align="center"
                            sx={{ maxWidth: 95, fontWeight: 700 }}
                          >
                            Before Bath-X<sup>®</sup>
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{ minWidth: 120, fontWeight: 700 }}
                          >
                            After 3.5 Days of Bath-X<sup>®</sup> (40 secs,
                            hourly)
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{ maxWidth: 120, fontWeight: 700 }}
                          >
                            After adjustments and 3 days more
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows.map((row) =>
                          row.className === "title" ? (
                            <TableRow stickyHeader>
                              <TableCell
                                colSpan={3}
                                align="left"
                                size="small"
                                sx={{
                                  fontWeight: 700,
                                  fontSize: "1rem",
                                  paddingLeft: "5px",
                                }}
                              >
                                {row.location}
                              </TableCell>
                            </TableRow>
                          ) : (
                            <>
                              <TableRow>
                                <TableCell
                                  size="small"
                                  colSpan={3}
                                  sx={{ border: 0, fontWeight: 100 }}
                                >
                                  {row.location}
                                </TableCell>
                              </TableRow>
                              <TableRow hover>
                                <TableCell size="small" align="center">
                                  {row.first}
                                </TableCell>
                                <TableCell size="small" align="center">
                                  {row.second}
                                </TableCell>
                                <TableCell size="small" align="center">
                                  {row.third}
                                </TableCell>
                              </TableRow>
                            </>
                          )
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <TableContainer
                    component={Paper}
                    sx={{ maxWidth: 1000, maxHeight: 440, margin: "auto" }}
                  >
                    <Table
                      stickyHeader
                      aria-label="Bath-X<sup>®</sup> ATP Results"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell></TableCell>
                          <TableCell align="center" sx={{ fontWeight: 700 }}>
                            Before Bath-X<sup>®</sup>
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{ minWidth: 120, fontWeight: 700 }}
                          >
                            After 3.5 Days of Bath-X<sup>®</sup> (40 secs,
                            hourly)
                          </TableCell>
                          <TableCell align="center" sx={{ fontWeight: 700 }}>
                            After adjustments and 3 days more
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows.map((row) =>
                          row.className === "title" ? (
                            <TableRow stickyHeader>
                              <TableCell
                                colSpan={4}
                                align="left"
                                size="small"
                                sx={{ fontWeight: 700, fontSize: "1rem" }}
                              >
                                {row.location}
                              </TableCell>
                            </TableRow>
                          ) : (
                            <TableRow hover>
                              <TableCell sx={{ paddingLeft: 5 }}>
                                {row.location}
                              </TableCell>
                              <TableCell align="center">{row.first}</TableCell>
                              <TableCell align="center">{row.second}</TableCell>
                              <TableCell align="center">{row.third}</TableCell>
                            </TableRow>
                          )
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}

                <Typography
                  variant="subtitle2"
                  sx={{
                    color: "#555",
                    margin: "auto",
                    marginTop: "15px",
                    fontStyle: "italic",
                    fontWeight: "100",
                    maxWidth: 1000,
                  }}
                >
                  ATP Data reported in RLU units measured with Hygiena
                  SystemSure Plus
                </Typography>
                <Typography
                  variant="subtitle2"
                  sx={{
                    color: "#555",
                    margin: "auto",
                    marginTop: "15px",
                    fontStyle: "italic",
                    width: "95%",
                    fontWeight: "100",
                    maxWidth: 1000,
                  }}
                >
                  Notes: <br /> 1. Typically very high bioburden and inacessible
                  to standard cleaning methods, very promising results. <br />{" "}
                  2. Disinfectant missed handle until adjustments were made,
                  handle had serrated, difficult to clean crevices. <br /> 3. We
                  identified additional factor causing this and other tub
                  results to be higher than expected.
                </Typography>
              </Box>
            </div>
          </Paper>
        </Box>
      </Grid>
    </div>
  );
}

export default BathX;
