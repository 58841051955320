import React from "react";
import "./HeroSection.css";
import { Fade } from "react-reveal";
import { isMobile } from "react-device-detect";

function HeroSection({ cssClass, aboveTitle, title, title2, subTitle, subSubTitle, homepage=false }) {
  return (
    <div className={cssClass}>
      <Fade bottom>
        <h3>{aboveTitle}</h3>
      </Fade>
      <Fade bottom>
        <h1 style={{width: isMobile ? "" : "50%", textAlign: "center"}}>
          {homepage ? <span>{title}<sup>®</sup></span> : title}
          {title2}
          <hr
            className="sexy-line-hero"
            style={{ width: isMobile ? "95%" : "100%" }}
          />
        </h1>

        <h2>{subTitle}</h2>
        <h4>{subSubTitle}</h4>
        <div className="hero-container-box"></div>
      </Fade>
    </div>
  );
}

export default HeroSection;
