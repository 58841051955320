import React from "react";

import "./App.css";
import Navbar from "./components/Navbar";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./components/pages/Home";
import Pillars from "./components/pages/Pillars";
import Footer from "./components/Footer";
import About from "./components/pages/About";
import Contact from "./components/pages/Contact";
import BathX from "./components/pages/BathX";

function App() {
  return (
    <div>
        <div className="site-wrapper">
      <Router>
        <Navbar />
          <Routes>
            <Route path="/" exact element={<Home />} />
            <Route path="/pillars" element={<Pillars />} />
            <Route path="/bathx" element={<BathX />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
          <Footer />
      </Router>
        </div>
    </div>
  );
}

export default App;
