import React from "react";
import HeroSection from "../HeroSection";
import "../../App.css";
import "./Home.css";
import { Grid, Box, Typography, Paper, Button } from "@mui/material";
import { isMobile } from "react-device-detect";
import { Fade } from "react-reveal";

function Home() {
  return (
    <div>
      <HeroSection
        cssClass="hero-container-home"
        title="Path-X"
        subTitle="Pathogen Extermination Technologies"
        subSubTitle="a Division of Lloyd Technologies, Inc."
        homepage={true}
      />
      <Grid container spacing={0} alignItems="center" justify="center">
        <Box
          width={isMobile ? "100%" : "80%"}
          alignItems="center"
          margin="auto"
          justifyContent="center"
          padding={isMobile ? "0px" : "20px"}
          backgroundColor="#EEE"
        >
          <Paper elevation={24}>
            <div className="home-section">
              <Fade bottom>
                <Grid
                  container
                  spacing={2}
                  direction={isMobile ? "column" : "row"}
                  alignItems="stretch"
                >
                  {/* <Grid item xs={4}>
                    <Paper className="pcb-image" elevation={5}></Paper>
                  </Grid> */}
                  <Grid item xs={12}>
                    <Typography variant="h2" align="center" fontWeight={700}>
                      Kill Germs Smarter
                      <hr
                        className="sexy-line"
                        style={{ width: isMobile ? "95%" : "50%" }}
                      />
                    </Typography>

                    <Typography variant="h4" align="center" mt={2}>
                      Because no one should get an infection in a health care
                      facility
                    </Typography>

                    {/* <Typography variant="h5" align="center" mt={2}>
                      <b>PathX™</b> is currently developing technologies to
                      simplify and take the guess work out of disinfection. With
                      our patent-pending technologies we are creating hands-off,
                      automated systems that will not add to your workload,
                      killing germs with minimal to no additional time and
                      providing <b>Disinfection as a Service™</b>.
                    </Typography> */}

                    <Typography variant="h4" align="center">
                      <br />
                      We can WIN the WAR on Healthcare Associated Infections
                      <br />
                    </Typography>

                    <Typography variant="h4" align="center" mt={2}>
                      We can SLOW the RISE of Antibiotic-Resistant Organisms
                      <br />
                    </Typography>
                  </Grid>
                </Grid>
              </Fade>
              <Fade bottom>
                <br />
                <hr />
                <br />
                <Grid
                  container
                  spacing={2}
                  direction={isMobile ? "column" : "row"}
                  alignItems="stretch"
                >
                  <Grid item xs={12}>
                    <Typography
                      variant="h3"
                      align="center"
                      mt={2}
                      fontWeight="700"
                      fontSize={isMobile ? "3.5rem" : "5rem"}
                    >
                      Bath-X<sup>®</sup>
                    </Typography>
                    <hr
                      className="sexy-line"
                      style={{ width: isMobile ? "95%" : "50%" }}
                    />
                    <Typography variant="h5" align="center" mt={2}>
                      <b>Bath-X<sup>®</sup></b> is an automated disinfection and sanitation
                      apparatus to disinfect equipment, occupied and unoccupied
                      rooms, including bathrooms and plumbing fixtures in
                      bathrooms, using automated dispensing into an environment
                      of chemical disinfectants to kill harmful microorganisms
                      on surfaces and in drain piping. For more information or
                      to purchase a Bath-X<sup>®</sup> device, contact us using the
                      "Contact Us" link
                      <br />
                      <br />
                      <Button
                        variant="contained"
                        href="/contact"
                        target={"_top"}
                        sx={{ fontSize: "20px" }}
                      >
                        Contact Us
                      </Button>
                    </Typography>
                  </Grid>
                </Grid>
              </Fade>
              <Fade bottom>
                <br />
                <hr />
                <br />
                <Grid
                  container
                  spacing={2}
                  direction={isMobile ? "column" : "row"}
                  alignItems="stretch"
                >
                  <Grid item xs={12}>
                    <Typography
                      variant="h3"
                      align="center"
                      mt={2}
                      fontWeight="700"
                      fontSize={isMobile ? "3.5rem" : "5rem"}
                    >
                      Eradicare<sup>®</sup>
                    </Typography>
                    <hr
                      className="sexy-line"
                      style={{ width: isMobile ? "95%" : "50%" }}
                    />
                    <Typography variant="h5" align="center" mt={2}>
                      <b>Eradicare<sup>®</sup></b> is an automated disinfection and
                      sanitation apparatus to disinfect equipment, unoccupied
                      rooms, including bathrooms and plumbing fixtures and other
                      surfaces in bathrooms, using automated dispensing into an
                      unoccupied environment of ultraviolet radiation to kill
                      harmful microorganisms on surfaces. Eradicare<sup>®</sup> may also be
                      used in conjunction with and an upgrade to the Bath-X<sup>®</sup>
                      system, adding safe and automated UV disinfection of
                      unoccupied rooms to further enhance the disinfecting power
                      of the chemicals emitted by the Bath-X<sup>®</sup> system. For more
                      information or to purchase an Eradicare<sup>®</sup> device, contact
                      us using the "Contact Us" link
                      <br />
                      <br />
                      <Button
                        variant="contained"
                        href="/contact"
                        target={"_top"}
                        sx={{ fontSize: "20px" }}
                      >
                        Contact Us
                      </Button>
                    </Typography>
                  </Grid>
                </Grid>
              </Fade>
              <Fade bottom>
                <br />
                <hr />
                <br />
                <Grid
                  container
                  spacing={2}
                  direction={isMobile ? "column" : "row"}
                  alignItems="stretch"
                >
                  <Grid item xs={12}>
                    <Typography
                      variant="h3"
                      align="center"
                      mt={2}
                      fontWeight="700"
                      fontSize={isMobile ? "3.5rem" : "5rem"}
                    >
                      Path-X<sup>®</sup>
                    </Typography>
                    <hr
                      className="sexy-line"
                      style={{ width: isMobile ? "95%" : "50%" }}
                    />
                    <Typography variant="h5" align="center" mt={2}>
                      <b>Path-X<sup>®</sup></b> is an automated disinfection and sanitation
                      apparatus to disinfect equipment and occupied and
                      unoccupied rooms using air filtration to remove harmful
                      airborne pathogens. Ideally, Path-X<sup>®</sup> is combined with the
                      Bath-X<sup>®</sup> and Eradicare<sup>®</sup> systems to offer some of the best
                      automated room or equipment disinfecting available in
                      which airborne pathogens are removed using filtration,
                      airborne and surface pathogens are killed using UV
                      emissions, and surface and drainage pathogens are
                      destroyed by the automated, periodic emission of
                      disinfecting chemicals. For more information or to
                      purchase a Path-X<sup>®</sup> device, contact us using the
                      "Contact Us" link
                      <br />
                      <br />
                      <Button
                        variant="contained"
                        href="/contact"
                        target={"_top"}
                        sx={{ fontSize: "20px" }}
                      >
                        Contact Us
                      </Button>
                    </Typography>
                  </Grid>
                </Grid>
              </Fade>
              <br />
              <hr />
              <br />
            </div>
          </Paper>
        </Box>
      </Grid>
    </div>
  );
}

export default Home;
